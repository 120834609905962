import React, { ReactElement } from 'react';

type contact = {
    name: string;
    link: string;
    svgPath: string;
}

interface Props
{
    socials : contact[];
    children?: string;
}

function Contacts(props : Props) 
{
    
    var links : ReactElement[] = [];
        props.socials.forEach((social, i) => {                        
            links.push(

            <li key={i}> 
                <a href={social.link} className='social-links'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="social-links" viewBox="0 0 24 24">
                        <title>{social.name}</title>      
                        <path d={social.svgPath}/>
                    </svg>
                </a>
            </li>);
        });

    return (<>
        <div id="contacts" className="shifted-right">

            <ul id="socials-list">
                {links}
            </ul>    
            <div id="contact-text">
                <p className="long-text">                        
                    {props.children}
                </p>
            </div>
        </div>
    </>);
}

export default Contacts;