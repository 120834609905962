import React, { useCallback, useEffect, useRef, useState } from "react"
import Popup from "reactjs-popup"
import Header from "./Header"
import Markdown from "react-markdown"
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

type project = {
    title: string;
    blurb: string;
    resourcesName: string;
    link?: string;
}

interface Props
{
    isOpen: boolean;
    onClose: () => void;

    project: project;
}

function ProjectPopup(props: Props)
{
    const [md, setmd] = useState<string>("")

    useEffect(() => {
        const myMarkdownFile = require(`../data/writeups/${props.project.resourcesName}.md`);
        fetch(myMarkdownFile)
        .then(response => response.text())
        .then((text: string) => {
            setmd(text)
        })
    }, [props.project.resourcesName])

    let popup = <Popup open={props.isOpen} closeOnDocumentClick onClose={props.onClose} modal>
        <div id="project-popup">
            <Header style={{color : "black", marginBottom: "0.5em"}}>{props.project.title}</Header>
            <Markdown className="shifted-right" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>{md}</Markdown>
            <button onClick={props.onClose}>&times;</button>
        </div>
    </Popup>
    
    
    return popup;
}
export default ProjectPopup;