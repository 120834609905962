import React from 'react';
import p5 from 'p5';

const sketch = (p) =>
{
    var backgroundColor = p.color("#45c7f8");
    var cloudColor = p.color("#0798cd");
    var footerCoefficients = [8, 16, 64, 24, 40];
    var footerColorAsString;
    let diagonalLength = Math.sqrt(Math.pow(1920, 2) + Math.pow(1080, 2));
    var cloudOptions = {
        amountOfClouds : 15,
        minChildCount : 3,
        maxChildCount : 7,
        //size with respect to window
        minCloudSize : 50 / diagonalLength,
        maxCloudSize : 100 / diagonalLength,
        minCloudScrollSpeed : 1,
        maxCloudScrollSpeed : 3,
        maxAdditionalSpeed : 5,
        speedVariation : 2,
        pieces : 2,
        //in miliseconds
        timeBeforeBreaking : 500
    };

    class Cloud 
    {
        
        minGenerations = 1;
        maxGenerations = 3;        
        percentChanceTohaveDecendants = 0.3;
        childCoeff = 0.5;


        constructor(location, size, speed, childCount, graphicSize)
        {
            this.location = location;
            this.mainCloudSize = size;
            this.movementSpeed = speed;
            this.childCount = childCount;
            this.timeCreated = p.millis();

            this.renderedClouds = p.createGraphics(graphicSize.x, graphicSize.y);
            this.renderedClouds.noStroke();
            this.renderedClouds.fill(cloudColor);
            this.renderedClouds.rect(0,0, this.mainCloudSize.x, this.mainCloudSize.y);     
            for (var i=0;i < this.childCount;i++)
            {
                this.CreateChildren(0, p.createVector(0,0), this.mainCloudSize);
            } 
        }

        CreateChildren(level, parentLocation, parentSize)
        {
            if (level >= this.maxGenerations) { return; }
            var cloudHeight = p.round(p.random(cloudOptions.minCloudSize * this.childCoeff, cloudOptions.maxCloudSize * this.childCoeff));
            var cloudWidth = p.round(p.random(cloudOptions.minCloudSize * this.childCoeff, cloudOptions.maxCloudSize * this.childCoeff)); 
            var cloudSize = p.createVector(cloudWidth, cloudHeight);
            var cloudLocation = p.createVector(p.round(p.random(0, parentSize.x) + parentLocation.x), p.round(p.random(0, parentSize.y) + parentLocation.y));
            
            this.renderedClouds.fill(cloudColor);
            this.renderedClouds.rect(cloudLocation.x, cloudLocation.y, cloudSize.x, cloudSize.y);       
        
            if ((level < this.maxGenerations-1 && p.random(0, 100) <= 100*this.percentChanceTohaveDecendants) || level < this.minGenerations)
            {
                var amountOfChild = p.round(p.random(cloudOptions.minChildCount, cloudOptions.maxChildCount));
                for (var i=0;i < amountOfChild;i++)
                {
                    this.CreateChildren(level+1, cloudLocation, cloudSize);
                }
            }
        }
        
        move()
        {
            this.location.x += this.movementSpeed.x;
            this.location.y += this.movementSpeed.y;
        }

        breakApart(mouseSpeedX, mouseSpeedY)
        {
            var cloudPieces = [];
            for (var i=0;i<cloudOptions.pieces;i++)
            {
                var speedX = p.abs(mouseSpeedX/10) > cloudOptions.maxAdditionalSpeed ? cloudOptions.maxAdditionalSpeed : p.abs(p.round(mouseSpeedX/10));
                var speedY = p.abs(mouseSpeedY/10) > cloudOptions.maxAdditionalSpeed ? Math.sign(mouseSpeedY) * cloudOptions.maxAdditionalSpeed : p.round(mouseSpeedY/10);
                speedX += p.round(p.random(-cloudOptions.speedVariation, cloudOptions.speedVariation));
                speedY += p.round(p.random(-cloudOptions.speedVariation, cloudOptions.speedVariation));
                var cloud = new Cloud(p.createVector(this.location.x, this.location.y), p.createVector(p.round(this.mainCloudSize.x/2), p.round(this.mainCloudSize.y/2)),
                     p.createVector(this.movementSpeed.x+speedX, this.movementSpeed.y+speedY), this.childCount/2,
                      p.createVector(this.renderedClouds.width/2, this.renderedClouds.height/2));
                cloudPieces.push(cloud);
            }
            return cloudPieces;
        }
        
        draw()
        {
            p.image(this.renderedClouds, this.location.x, this.location.y);
        }
    }
    //creates and return a new cloud based on current cloud specification
    function CreateNewCloud(options) 
    {
        var location = p.createVector( p.round(p.random(-p.width, -cloudOptions.maxCloudSize*2)), p.round(p.random(0, p.height)) );
        var size = p.createVector(p.round(p.random(options.minCloudSize, options.maxCloudSize)), p.round(p.random(options.minCloudSize, options.maxCloudSize)));
        var childCount = p.round(p.random(options.minChildCount, options.maxChildCount));
        var speed = p.createVector(p.round(p.random(options.minCloudScrollSpeed, options.maxCloudScrollSpeed)), 0);

        return new Cloud(location, size, speed, childCount, p.createVector(options.maxCloudSize*2, options.maxCloudSize*2));  
    }

    function CreateFooterShapes()
    {
        footerColorAsString = getStyle(document.getElementById("body"), "backgroundColor");
        var color = p.color(footerColorAsString);                
        footerShapes = p.createGraphics(p.windowWidth, p.round(p.windowWidth/Math.min(...footerCoefficients)));

        footerShapes.noStroke();
        footerShapes.fill(color);
        var offsets  = 0;
        footerShapes.rect(0, footerShapes.height-10, footerShapes.width, 10);
        for (var i = 0;i < footerCoefficients.length;i++)
        {
            var boxSize = p.round(footerShapes.width/footerCoefficients[i]);            
            footerShapes.rect(offsets, footerShapes.height-boxSize, boxSize, boxSize);
            footerShapes.rect(footerShapes.width - offsets - boxSize, footerShapes.height-boxSize, boxSize, boxSize);
            offsets += boxSize;
        }             
    }

    function getStyle(el,styleProp)
    {
        if (el.currentStyle)
            return el.currentStyle[styleProp];

        return document.defaultView.getComputedStyle(el,null)[styleProp];
    }

    var clouds = [];
    var footerShapes = p.createGraphics(p.windowWidth, p.round(p.windowHeight/5));
    p.setup = () => 
    {    
        CreateFooterShapes();                
        var cnv = p.createCanvas(p.windowWidth, p.windowHeight);
        cnv.id("cloud-background");
        cloudOptions.minCloudSize = p.round(cloudOptions.minCloudSize*Math.sqrt(Math.pow(p.windowHeight, 2) + Math.pow(p.windowWidth, 2)));
        cloudOptions.maxCloudSize = p.round(cloudOptions.maxCloudSize*Math.sqrt(Math.pow(p.windowHeight, 2) + Math.pow(p.windowWidth, 2)));
        for(var i=0;i<cloudOptions.amountOfClouds;i++)
        {
            var cloud = CreateNewCloud(cloudOptions);
            clouds.push(cloud);
        }
    }

    
    p.draw = () =>
    {        
        p.background(backgroundColor);
        for (var i=0;i < clouds.length;i++)
        {
            clouds[i].move();
            clouds[i].draw();
            if (clouds[i].location.x > p.width || clouds[i].location.x + clouds[i].renderedClouds.width < -p.width || 
                clouds[i].location.y > p.height || clouds[i].location.y + clouds[i].renderedClouds.height < 0)
                {
                    if (clouds.length > 10) {clouds.splice(i, 1);}
                    else
                    {
                        clouds[i] = CreateNewCloud(cloudOptions);                        
                }
            }
        }

        //calculate the location of the scroll from the translate in the css
        var scrolledHeightString = document.getElementById('cloud-background').style.transform;
        scrolledHeightString = scrolledHeightString.substring(11, scrolledHeightString.length-3);
        var scrolledHeight = (scrolledHeightString === "") ? 0 : parseInt(scrolledHeightString);
        p.image(footerShapes, 0, p.height-footerShapes.height-scrolledHeight);
        p.noStroke();
        p.fill(footerColorAsString);
        p.rect(0, p.height-scrolledHeight, p.width, p.height);
    }
    p.mouseMoved = () => 
    {
        for (var i=0;i<clouds.length;i++)
        {
            if (p.mouseX > clouds[i].location.x && p.mouseX < clouds[i].location.x+clouds[i].renderedClouds.width)
            {
                if (p.mouseY > clouds[i].location.y && p.mouseY < clouds[i].location.y+clouds[i].renderedClouds.height)
                {
                    if (p.millis() - clouds[i].timeCreated > cloudOptions.timeBeforeBreaking)
                    {
                        var newClouds = clouds[i].breakApart(p.mouseX-p.pmouseX, p.mouseY-p.pmouseY);
                        clouds.splice(i, 1);                    
                        clouds = clouds.concat(newClouds);
                        break;
                    }
                } 
            }
        }
    }

    p.windowResized = () =>
    {
        CreateFooterShapes();
        p.resizeCanvas(p.windowWidth, p.windowHeight);
    }
}


class CloudBackground extends React.Component
{    
    constructor()
    {
        super();
        this.board = React.createRef();
        this.state = {scrolledHeight : 1}
    }

    componentDidMount() {
        this.myP5 = new p5(sketch, this.board.current);
    }

    render()
    {   
        return (
            <div ref={this.board}></div>
        );
    }
}

export default CloudBackground;