import React from 'react';
import CloudBackground from './components/CloudBackground';
import KnowledgeList from './components/KnowledgeList';
import Header from './components/Header';
import ProjectGallery from './components/ProjectGallery';
import Contacts from './components/Contacts';

const projects = require("./data/projects.json");

var knowledgeIcons = require("./data/skillset.json");

const socials = require("./data/contacts.json");

const birthDate = {
  day: 12,
  month: 12,
  year: 2004
};

class App extends React.Component
{  

  state = {
    descriptionOpen : false,
    shownKnowledge : null,    
    mouse : {
      X : 0,
      Y : 0,
      timeStamp : 0
    }
  }
  constructor()
  {
    super();    
    this.updateDescription = this.updateDescription.bind(this);     
    this.getAge = this.getAge.bind(this);
  }

  componentDidMount()
  {    
    var application = document.getElementById("App");

    // application.addEventListener("mousemove", e=> {      
    //   this.setState({
    //     mouse : {
    //       X : e.pageX,
    //       Y : e.pageY,
    //       timeStamp : Date.now()
    //     }
    //   });
    // });

    application.addEventListener("scroll", () => {
      var scrolledPixel = application.scrollTop;      
      var cloudBackground = document.getElementById("cloud-background");         
      var backgroundHeight = cloudBackground.style.height;
      backgroundHeight = backgroundHeight.substring(0, cloudBackground.style.height.length-2);
      if (scrolledPixel < parseInt(backgroundHeight))
      {
        cloudBackground.style.transform = `translateY(${scrolledPixel}px)`              
      }
    });
    
    // let bouncyElements = document.getElementsByClassName('bouncy');
    // let bounceCoefficient = 5;    
    // for (let element of bouncyElements)
    // {     
    //   element.addEventListener("mouseenter", e => {
    //     let speedX = (e.pageX - this.state.mouse.X)/(Date.now()-this.state.mouse.timeStamp);
    //     let speedY = (e.pageY - this.state.mouse.Y)/(Date.now()-this.state.mouse.timeStamp);          
    //     element.style.transform = `translate(${Math.round(speedX*bounceCoefficient)}px, ${Math.round(speedY*bounceCoefficient)}px)`;
    //     element.addEventListener('transitionend', () => {
    //       element.style.transform = `translate(0px, 0px)`; 
    //     }, {once : true});        
    //   });
    // }
  }

  render()
  {    
    const introduction = 
    <div id="introductions">            
      <div>
        <div id="greetings">
          Hello! I am
          <h1>Yi Wei</h1>
        </div>
        <p id="brief-summary">
          I am someone who really likes making <br/>
          things with different technologies,<br/>
          from websites to eletronics.<br/>          
        </p>
      </div>
    </div>

    return (
      <div id="App">
        {introduction}
        <CloudBackground/>

        <div id="body">          

          <Header>About Me :</Header>
          <p id="about-me" className="shifted-right long-text">
          I am a {this.getAge()} year old student and maker
          <br/>
          <br/>
          From micro-controllers to deep learning and building websites, 
          <br/> 
          I like dabbling in different technologies and making something interesting with them.
          <br/> 
          <br/> 
          Currently a student in Singapore Polytechnic.
          </p> 

          <Header>Some Of My Projects:</Header>
          <ProjectGallery projects={projects}/>

          <Header>Contact Me :</Header>
          <Contacts socials={socials}>
            Feel free to contact me from any of these platforms! <br/>
          </Contacts>
      
          
        </div>
      </div>
    );
  }

  updateDescription(buttonName)
  {    
    var description = document.getElementById('knowledge-description');
    let opened = this.state.descriptionOpen;
    let desc = this.state.shownKnowledge;

    let knowledge = knowledgeIcons[0];    
    for (let i=0;i<knowledgeIcons.length;i++)
    {
      if (buttonName === knowledgeIcons[i].name)
      { knowledge = knowledgeIcons[i]; }
    }

    //show description, or change to a different text
    if (description.classList.contains('close-description') ||
     (description.classList.contains('open-description') && knowledge !== this.state.shownKnowledge))
    {     
      opened = true;
      desc = knowledge;
    }
    //close description
    else if (description.classList.contains('open-description') && knowledge === this.state.shownKnowledge)
    {      
      opened = false;
      desc = null;
    }
    this.setState({
      descriptionOpen : opened,
      shownKnowledge : desc
    });          
  }

  getAge()
  {
    let today = new Date();    
    if (today.getMonth()+1 >= birthDate.month && today.getDate() >= birthDate.day)
    {
      return today.getFullYear()-birthDate.year;
    }
    return today.getFullYear()-birthDate.year-1;
  }
}

export default App;
