import React from "react";

interface Props
{
    children: string;
    style: React.CSSProperties;
}

function Header(props : Props)
{
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {if (entry.isIntersecting) {setVisible(true);}});
        });
        if (domRef.current != null)
        {
            observer.observe(domRef.current);
        }
        return () => {if (domRef.current != null) {observer.unobserve(domRef.current) }} ;
      }, []);

    return (<>
        <h1 ref={domRef} style={props.style} className={`header-titles shifted-right ${isVisible ? "animate" : ""}`}>{props.children}</h1>
        <div style={{height: "1.5em"}}></div>
    </>);
}

export default Header;